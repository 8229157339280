import React from "react";
import PrimaryButton from "../../components/PrimaryButton";

const LoginForm = (props) => {
  const [formData, setFormData] = React.useState({
    email: "",
    password: "",
    errorMessage: null,
  });

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.id]: event.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const { email, password } = formData;
    if (!email || !password) {
      setFormData({
        ...formData,
        errorMessage: "You must provide both fields",
      });

      return false;
    }

    formData.email = formData.email.toLowerCase();

    props.onLogin(formData);
  };

  return (
    <div className="Login">
      <h2>Login</h2>
      <div>
        If you have an existing <strong>nobs guides™</strong> account, you can
        login with it!
      </div>
      <form className="form-signin mt-4">
        <div className="form-label-group">
          <input
            autoFocus
            type="email"
            id="email"
            className="form-control"
            placeholder="Email"
            required
            value={formData.email}
            onChange={handleChange}
          />
        </div>
        <div className="form-label-group">
          <input
            type="password"
            id="password"
            className="form-control"
            placeholder="Password"
            required
            value={formData.password}
            onChange={handleChange}
          />
        </div>
        <PrimaryButton isFullWidth label="Login" onClickAction={handleSubmit} />
        {formData.errorMessage && (
          <div className="error-message">{formData.errorMessage}</div>
        )}
      </form>
    </div>
  );
};

export default LoginForm;
